<template> 
  <div>
    <div>
    <!-- Validation modal -->
			<b-modal
				ref="skip-banking-solution-modal"
				hide-footer
				v-b-modal.modal-center
    			no-close-on-backdrop
				modal-backdrop
				hide-header
				id="skip-banking-solution-modal"
				centered
			>
				<div class="color">
					<div class="col-12 text-center">
						<svg
							version="1.1"
							id="Layer_1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							x="0px"
							y="0px"
							width="120"
							height="120"
							viewBox="0 0 100 125"
							style="enable-background: new 0 0 100 125"
							xml:space="preserve"
							fill="#e14343"
						>
							<path
							d="M96.2,47.5l-22-38c-0.4-0.6-1-1-1.7-1h-44c-0.7,0-1.4,0.4-1.7,1l-22,37.9c-0.4,0.6-0.4,1.4,0,2l22,38.1c0.4,0.6,1,1,1.7,1
					h44c0.7,0,1.4-0.4,1.7-1l22-38C96.6,48.9,96.6,48.1,96.2,47.5z M71.3,84.5H29.7L8.8,48.4l20.8-35.9h41.7l20.8,36L71.3,84.5z
					M50.5,60.5c1.1,0,2-0.9,2-2v-30c0-1.1-0.9-2-2-2c-1.1,0-2,0.9-2,2v30C48.5,59.6,49.4,60.5,50.5,60.5z M48.4,66.4
					c-0.6,0.6-0.9,1.3-0.9,2.1c0,0.8,0.3,1.6,0.9,2.1s1.3,0.9,2.1,0.9c0.8,0,1.6-0.3,2.1-0.9c0.6-0.6,0.9-1.3,0.9-2.1
					c0-0.8-0.3-1.6-0.9-2.1C51.5,65.3,49.5,65.3,48.4,66.4z"
							/>
						</svg>
					</div>
					<div class="purchaserpower-modal-text">
						<div class="d-block text-center">
							<label class="purchasepower-def-label left-align">
								{{ error_message }}
							</label>
						</div>
						<br />
						<br />
						<div class="text-center">
							<button
								type="button"
								class="mx-auto col-10 offset-1 btn-black"
								style="height: 60px"
								@click="skipBankingSolution"
							>
								<label class="purchasepower-modal-ok-label">Try Again</label>
							</button>
						</div>
					</div>
				</div>
			</b-modal>
    
  </div>
</div>
    
</template>
<script>
import Loading from "vue-loading-overlay";

  export default {
    name: "SkipBankingSolutionModal",
    components: {
      Loading,
    },
    data() {
        return {
          error_message: 'There was an error linking your bank via this method. CanPay recently added another bank linking option for you to try. Please select "Try Again" below and you will be taken there.',
          consumer_local_storage_details: JSON.parse(
            localStorage.getItem("consumer_login_response")
          ),
        };
    },
    methods: {
    
    skipBankingSolution() {
      let self = this;
      self.$bvModal.hide("skip-banking-solution-modal");
      if(self.skipRedirection){
      	localStorage.removeItem('skip_current_banking_solution');
        self.$parent.findRetryBank();
      } else {
        if (localStorage.getItem('consumer_token') != null) {
          this.$router.push("/banklinking");
        } else if (localStorage.getItem('session_data') != null) {
          localStorage.setItem("session_exists",localStorage.getItem('session_data'));
          localStorage.setItem("session_step",6);
          this.$router.push("/registration"); //redirect to registration
        } else if (localStorage.getItem('sessionId') != null) { // Onboarding
			this.$router.push(
			"/onboringconnectbankaccount/" +
				btoa(localStorage.getItem('sessionId')) +
				"/onboringconnectbankaccount"
			);
        }
      }
    },
        
    }, 
    props: {
		skipRedirection : false
	},
    mounted: function () {
    }
  };
</script>
<style lang="scss">
#skip-banking-solution-modal___BV_modal_body_ {
  background-color: #ffffff;
  border-radius: 12px;
  margin: 10px;
}
</style>