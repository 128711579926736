const EmailVerification = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/verifydetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};


const EmailVerificationCode = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/verifyverificationcode', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const DuplicateMobileNumber = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/checkphoneusingtwilio', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};


const MobileVerificationCode = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/checkotp', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const QuickAccessPinSet = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/setconsumerpin', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const RedirectToValidStep = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/redirecttovalidstep', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};


const GetBankDetails = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/getbankdetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const ConnectBank = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/generateConnectURL', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};





const  Finalonboarding = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/finalonboarding', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const  ValidateBankdetail = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/validatebankdetails', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const  checkV1ConsumerManualReviewStatus = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/checkv1consumermanualreviewstatus', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const  onboardingManualReview = (request) => {
    return new Promise((res, rej) => {
        axios.post('onboarding/onboardingmanualreview', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};

const checkUserStatus = (request) => {
    return new Promise((res, rej) => {
        axios.post('/onboarding/checkv1consumerstatus', request)
            .then((response) => {
                res(response.data);
            })
            .catch((err) => {
                rej(err);
            })
    })
};
export default {
    ValidateBankdetail, Finalonboarding,  EmailVerification,EmailVerificationCode,DuplicateMobileNumber,MobileVerificationCode,QuickAccessPinSet,RedirectToValidStep,GetBankDetails,ConnectBank,checkV1ConsumerManualReviewStatus,onboardingManualReview,checkUserStatus
};