<template>
<div>
  <div v-if="isLoading">
	<CanPayLoader/>
  </div>
 <div class="container" style="height:90vh;">
		<div>
			<!-- Validation modal -->
			<b-modal
				ref="validation-modal"
				hide-footer
				v-b-modal.modal-center
        no-close-on-backdrop
				modal-backdrop
				hide-header
				id="validation-modal"
				centered
			>
				<div class="color">
					<div class="purchaserpower-modal-text">
						<div class="d-block text-center">
							<label class="purchasepower-def-label">
								{{ error_message }}
							</label>
						</div>
						<div class="text-center">
							<button
								type="button"
								class="mx-auto col-10 offset-1 btn-black"
								style="height: 60px"
                				@click="redirectBankListPage()"
							>
								<label class="purchasepower-modal-ok-label">{{link_success ? 'OK' : 'Try Again'}}</label>
							</button>
						</div>
					</div>
				</div>
			</b-modal>
			
			<skip-banking-solution-modal ref="SkipBankingSolutionModal"></skip-banking-solution-modal>
		</div>
	</div>
      <!-----------------------  MODAL FOR BANK FIX SUCCESS  !---------------->
      <div>
        <b-modal
          ref="bank-link-success"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          no-close-on-backdrop
          id="bank-link-success"
          centered
          title="BootstrapVue"
        >
        <div style="text-align:center;">
<svg data-v-877fe06c="" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1024 1280" xml:space="preserve" width="60" height="60" fill="#149240"><g data-v-877fe06c=""><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M904.2,390.1c-7,0-14,0-21,0c-19,0-37.9,0-56.9,0c-28.2,0-56.3,0-84.5,0c-34.2,0-68.4,0-102.7,0c-37.6,0-75.3,0-112.9,0
                    c-38,0-76,0-114,0c-35.3,0-70.6,0-105.8,0c-29.9,0-59.9,0-89.8,0c-21.4,0-42.9,0-64.3,0c-10.2,0-20.4-0.3-30.6,0
                    c-0.4,0-0.9,0-1.3,0c6.7,6.7,13.3,13.3,20,20c0-28.2,0-56.4,0-84.6c-3.3,5.8-6.6,11.5-9.9,17.3c12.9-6.7,25.8-13.5,38.7-20.2
                    c31-16.1,62-32.3,92.9-48.4c37.3-19.5,74.7-38.9,112-58.4c32.5-17,65-33.9,97.6-50.9c15.6-8.2,31.7-15.8,47.1-24.5
                    c0.2-0.1,0.5-0.2,0.7-0.4c-6.7,0-13.5,0-20.2,0c13.1,6.7,26.1,13.4,39.2,20.1c31.5,16.2,63,32.3,94.5,48.5
                    c38,19.5,76,39,114,58.5c33,16.9,65.9,33.8,98.9,50.7c15.9,8.1,31.6,17.1,47.8,24.5c0.2,0.1,0.4,0.2,0.7,0.3
                    c-3.3-5.8-6.6-11.5-9.9-17.3c0,28.2,0,56.4,0,84.6c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-28.2,0-56.4,0-84.6
                    c0-6.7-3.7-14.1-9.9-17.3c-13.1-6.7-26.1-13.4-39.2-20.1c-31.2-16-62.5-32.1-93.7-48.1c-37.8-19.4-75.7-38.8-113.5-58.3
                    c-33-16.9-66-33.9-99-50.8c-16.3-8.4-32.4-17-48.9-25.1c-7.1-3.5-14-3.9-21.3-0.3c-1.5,0.7-2.9,1.5-4.4,2.3
                    c-7.7,4-15.4,8-23.1,12c-29.1,15.2-58.2,30.3-87.3,45.5c-37.7,19.6-75.3,39.3-113,58.9c-34,17.7-68.1,35.5-102.1,53.2
                    c-18.7,9.8-37.5,19.5-56.2,29.3c-0.9,0.4-1.7,0.9-2.6,1.3c-6.2,3.2-9.9,10.5-9.9,17.3c0,28.2,0,56.4,0,84.6c0,10.8,9.2,20,20,20
                    c7,0,14,0,21,0c19,0,37.9,0,56.9,0c28.2,0,56.3,0,84.5,0c34.2,0,68.4,0,102.7,0c37.6,0,75.3,0,112.9,0c38,0,76,0,114,0
                    c35.3,0,70.6,0,105.8,0c29.9,0,59.9,0,89.8,0c21.4,0,42.9,0,64.3,0c10.2,0,20.4,0.2,30.6,0c0.4,0,0.9,0,1.3,0
                    c10.5,0,20.5-9.2,20-20C923.7,399.3,915.4,390.1,904.2,390.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M924,881.1c-7.4,0-14.8,0-22.1,0c-20,0-40,0-59.9,0c-29.5,0-59,0-88.6,0c-36,0-72,0-108,0c-39.6,0-79.2,0-118.8,0
                    c-39.8,0-79.6,0-119.5,0c-37.1,0-74.3,0-111.4,0c-31.4,0-62.8,0-94.2,0c-22.7,0-45.3,0-68,0c-10.7,0-21.4-0.2-32.1,0
                    c-0.5,0-0.9,0-1.4,0c-10.5,0-20.5,9.2-20,20s8.8,20,20,20c7.4,0,14.8,0,22.1,0c20,0,40,0,59.9,0c29.5,0,59,0,88.6,0
                    c36,0,72,0,108,0c39.6,0,79.2,0,118.8,0c39.8,0,79.6,0,119.5,0c37.1,0,74.3,0,111.4,0c31.4,0,62.8,0,94.2,0c22.7,0,45.3,0,68,0
                    c10.7,0,21.4,0.2,32.1,0c0.5,0,0.9,0,1.4,0c10.5,0,20.5-9.2,20-20C943.5,890.3,935.2,881.1,924,881.1L924,881.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M391.3,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
                    c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
                    c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
                    C400.5,490.6,391.3,498.9,391.3,510.1L391.3,510.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M230,901.1c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7
                    c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20s-20,8.8-20,20c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3
                    c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20
                    C220.8,920.7,230,912.4,230,901.1L230,901.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M794,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
                    c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
                    c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
                    C803.2,490.6,794,498.9,794,510.1L794,510.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M592.7,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
                    c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
                    c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
                    C601.8,490.6,592.7,498.9,592.7,510.1L592.7,510.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M537.9,286.4c0,1.1-0.1,2.3-0.1,3.5c0,2.9,1.1-5,0.1-0.6c-0.4,1.7-0.7,3.5-1.3,5.2c-0.1,0.4-0.3,0.8-0.4,1.3
                    c-0.6,1.6-0.6,1.7,0.1,0.1c0.2-0.4,0.3-0.8,0.5-1.2c-0.4,0.8-0.7,1.6-1.1,2.4c-0.2,0.4-3,5.7-3.4,5.6c-0.2-0.1,2.8-3.2,0.7-1
                    c-0.7,0.8-1.4,1.6-2.2,2.4c-0.4,0.4-3.7,4.1-4.3,3.9c-0.2-0.1,3.6-2.4,0.9-0.8c-0.8,0.4-1.5,0.9-2.2,1.4c-0.9,0.6-1.9,1-2.9,1.5
                    c-0.6,0.3-1.2,0.6-1.8,0.8c2.6-1.1,3.1-1.3,1.7-0.8c-1.9,0.2-3.9,1.2-5.8,1.6c0.1,0-2.6,0.6-2.7,0.5c0.3,0.4,4.7-0.4,0.5-0.3
                    c-1.7,0.1-4.6-0.6-6.2-0.1c2.4-0.7,3.5,0.7,1.3,0.2c-1.3-0.3-2.6-0.5-3.9-0.9c-1-0.3-2.2-0.9-3.2-1c-2.6-0.3,4.2,2.2,0.5,0.3
                    c-1.5-0.8-3.1-1.6-4.6-2.5c-0.4-0.2-0.7-0.5-1.1-0.7c-1.4-1-1.4-1-0.1,0c0.3,0.3,0.7,0.5,1,0.8c-0.7-0.5-1.3-1.1-2-1.7
                    c-1.6-1.4-3.1-3-4.5-4.6c-2.6-2.8,0.9,0.9,0.7,1c-0.1,0.1-1.6-2.4-1.8-2.7c-0.2-0.4-3.1-4.7-2.7-5.2c0.2-0.3,1.4,4.2,0.6,1.1
                    c-0.3-1.1-0.7-2.1-0.9-3.2c-0.3-1.1-0.5-2.2-0.7-3.3c-0.9-3.9,0,4.2,0.1-0.1c0-1.8,0-3.7,0-5.6c0.1-4.1-0.1,1.4-0.3,1.2
                    c-0.2-0.2,0.5-2.9,0.6-3.3c0.1-0.3,1.3-5.8,1.7-5.7c0.2,0-1.9,3.9-0.5,1.1c0.6-1.2,1.2-2.3,1.8-3.5c0.5-0.9,1.4-1.8,1.7-2.7
                    c0.9-2.4-3.2,3.5-0.4,0.4c1.2-1.3,2.3-2.6,3.6-3.8c0.8-0.7,1.7-1.4,2.4-2.1c1.8-1.9-4.1,2.5-0.4,0.4c1.9-1.1,3.7-2.2,5.7-3.2
                    c3.4-1.8-1.1,0.6-1.1,0.5c0-0.2,2.8-0.9,3.1-1c1.9-0.6,4-0.7,5.9-1.3c-4.2,1.2-2.8,0.3-1.2,0.3c1.2,0,2.3-0.1,3.5-0.1
                    c0.4,0,3.4,0,3.5,0.2c-0.1-0.3-4.9-1,0,0.1c0.5,0.1,6.4,1.4,6.4,1.9c0,0.2-3.9-1.9-1.1-0.5c1.2,0.6,2.3,1.2,3.5,1.8
                    c0.7,0.4,1.5,1,2.2,1.4c3.6,2-2.7-2.6,0.1,0c1.8,1.6,3.4,3.3,5.1,5c2.8,3-0.4-0.5-0.3-0.5c0.2-0.1,2,3.1,2.1,3.3
                    c0.4,0.8,0.9,1.5,1.3,2.3c0.3,0.6,0.6,1.2,0.9,1.8c0.6,1.4,0.4,0.8-0.8-1.7c0.5,0,1.7,5.9,1.9,6.4c0.1,0.4,0.2,0.9,0.3,1.3
                    c0.3,1.8,0.3,1.8,0,0c-0.2-1.8-0.3-1.7-0.1,0.1C537.9,284.6,537.9,285.5,537.9,286.4c0.2,10.5,9.1,20.5,20,20
                    c10.7-0.5,20.2-8.8,20-20c-0.4-28.1-18-52.4-44.3-62c-24.6-8.9-54.5-0.7-71,19.5c-17.6,21.5-21.1,52-6.5,76.1
                    c14.6,24.2,42.4,35.4,69.8,30.5c30.3-5.5,51.6-34.1,52.1-64.1c0.2-10.5-9.3-20.5-20-20C546.9,266.9,538,275.2,537.9,286.4z"></path></g></g></g></svg>

         <p style="font-family:'montserrat';font-weight:bolder;font-size:15px;margin-top:15px;">The connection to your bank has been restored. Thank you.</p>
         <button class="black-button-ok" @click="hideModal('bank-link-success')">OK</button>
        </div>
        </b-modal>
      </div>
      <!-----------------------  MODAL FOR BANK FIX SUCCESS  !----------------->
      <!-----------------------  MODAL FOR BANK FIX FAILURE  !---------------->
      <div>
        <b-modal
          ref="bank-link-error"
          hide-footer
          v-b-modal.modal-center
          modal-backdrop
          hide-header
          no-close-on-backdrop
          id="bank-link-error"
          centered
          title="BootstrapVue"
        >
        <div style="text-align:center;">
<svg data-v-877fe06c="" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1024 1280" xml:space="preserve" height="60" width="60" fill="#149240"><g data-v-877fe06c=""><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M904.2,390.1c-7,0-14,0-21,0c-19,0-37.9,0-56.9,0c-28.2,0-56.3,0-84.5,0c-34.2,0-68.4,0-102.7,0c-37.6,0-75.3,0-112.9,0
                    c-38,0-76,0-114,0c-35.3,0-70.6,0-105.8,0c-29.9,0-59.9,0-89.8,0c-21.4,0-42.9,0-64.3,0c-10.2,0-20.4-0.3-30.6,0
                    c-0.4,0-0.9,0-1.3,0c6.7,6.7,13.3,13.3,20,20c0-28.2,0-56.4,0-84.6c-3.3,5.8-6.6,11.5-9.9,17.3c12.9-6.7,25.8-13.5,38.7-20.2
                    c31-16.1,62-32.3,92.9-48.4c37.3-19.5,74.7-38.9,112-58.4c32.5-17,65-33.9,97.6-50.9c15.6-8.2,31.7-15.8,47.1-24.5
                    c0.2-0.1,0.5-0.2,0.7-0.4c-6.7,0-13.5,0-20.2,0c13.1,6.7,26.1,13.4,39.2,20.1c31.5,16.2,63,32.3,94.5,48.5
                    c38,19.5,76,39,114,58.5c33,16.9,65.9,33.8,98.9,50.7c15.9,8.1,31.6,17.1,47.8,24.5c0.2,0.1,0.4,0.2,0.7,0.3
                    c-3.3-5.8-6.6-11.5-9.9-17.3c0,28.2,0,56.4,0,84.6c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-28.2,0-56.4,0-84.6
                    c0-6.7-3.7-14.1-9.9-17.3c-13.1-6.7-26.1-13.4-39.2-20.1c-31.2-16-62.5-32.1-93.7-48.1c-37.8-19.4-75.7-38.8-113.5-58.3
                    c-33-16.9-66-33.9-99-50.8c-16.3-8.4-32.4-17-48.9-25.1c-7.1-3.5-14-3.9-21.3-0.3c-1.5,0.7-2.9,1.5-4.4,2.3
                    c-7.7,4-15.4,8-23.1,12c-29.1,15.2-58.2,30.3-87.3,45.5c-37.7,19.6-75.3,39.3-113,58.9c-34,17.7-68.1,35.5-102.1,53.2
                    c-18.7,9.8-37.5,19.5-56.2,29.3c-0.9,0.4-1.7,0.9-2.6,1.3c-6.2,3.2-9.9,10.5-9.9,17.3c0,28.2,0,56.4,0,84.6c0,10.8,9.2,20,20,20
                    c7,0,14,0,21,0c19,0,37.9,0,56.9,0c28.2,0,56.3,0,84.5,0c34.2,0,68.4,0,102.7,0c37.6,0,75.3,0,112.9,0c38,0,76,0,114,0
                    c35.3,0,70.6,0,105.8,0c29.9,0,59.9,0,89.8,0c21.4,0,42.9,0,64.3,0c10.2,0,20.4,0.2,30.6,0c0.4,0,0.9,0,1.3,0
                    c10.5,0,20.5-9.2,20-20C923.7,399.3,915.4,390.1,904.2,390.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M924,881.1c-7.4,0-14.8,0-22.1,0c-20,0-40,0-59.9,0c-29.5,0-59,0-88.6,0c-36,0-72,0-108,0c-39.6,0-79.2,0-118.8,0
                    c-39.8,0-79.6,0-119.5,0c-37.1,0-74.3,0-111.4,0c-31.4,0-62.8,0-94.2,0c-22.7,0-45.3,0-68,0c-10.7,0-21.4-0.2-32.1,0
                    c-0.5,0-0.9,0-1.4,0c-10.5,0-20.5,9.2-20,20s8.8,20,20,20c7.4,0,14.8,0,22.1,0c20,0,40,0,59.9,0c29.5,0,59,0,88.6,0
                    c36,0,72,0,108,0c39.6,0,79.2,0,118.8,0c39.8,0,79.6,0,119.5,0c37.1,0,74.3,0,111.4,0c31.4,0,62.8,0,94.2,0c22.7,0,45.3,0,68,0
                    c10.7,0,21.4,0.2,32.1,0c0.5,0,0.9,0,1.4,0c10.5,0,20.5-9.2,20-20C943.5,890.3,935.2,881.1,924,881.1L924,881.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M391.3,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
                    c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
                    c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
                    C400.5,490.6,391.3,498.9,391.3,510.1L391.3,510.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M230,901.1c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7
                    c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20s-20,8.8-20,20c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3
                    c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20
                    C220.8,920.7,230,912.4,230,901.1L230,901.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M794,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
                    c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
                    c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
                    C803.2,490.6,794,498.9,794,510.1L794,510.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M592.7,510.1c0,12.9,0,25.9,0,38.8c0,31.1,0,62.2,0,93.3c0,37.6,0,75.3,0,112.9c0,32.6,0,65.1,0,97.7
                    c0,15.9-0.4,31.8,0,47.7c0,0.2,0,0.4,0,0.7c0,10.5,9.2,20.5,20,20s20-8.8,20-20c0-12.9,0-25.9,0-38.8c0-31.1,0-62.2,0-93.3
                    c0-37.6,0-75.3,0-112.9c0-32.6,0-65.1,0-97.7c0-15.9,0.4-31.8,0-47.7c0-0.2,0-0.4,0-0.7c0-10.5-9.2-20.5-20-20
                    C601.8,490.6,592.7,498.9,592.7,510.1L592.7,510.1z"></path></g></g><g data-v-877fe06c=""><g data-v-877fe06c=""><path data-v-877fe06c="" d="M537.9,286.4c0,1.1-0.1,2.3-0.1,3.5c0,2.9,1.1-5,0.1-0.6c-0.4,1.7-0.7,3.5-1.3,5.2c-0.1,0.4-0.3,0.8-0.4,1.3
                    c-0.6,1.6-0.6,1.7,0.1,0.1c0.2-0.4,0.3-0.8,0.5-1.2c-0.4,0.8-0.7,1.6-1.1,2.4c-0.2,0.4-3,5.7-3.4,5.6c-0.2-0.1,2.8-3.2,0.7-1
                    c-0.7,0.8-1.4,1.6-2.2,2.4c-0.4,0.4-3.7,4.1-4.3,3.9c-0.2-0.1,3.6-2.4,0.9-0.8c-0.8,0.4-1.5,0.9-2.2,1.4c-0.9,0.6-1.9,1-2.9,1.5
                    c-0.6,0.3-1.2,0.6-1.8,0.8c2.6-1.1,3.1-1.3,1.7-0.8c-1.9,0.2-3.9,1.2-5.8,1.6c0.1,0-2.6,0.6-2.7,0.5c0.3,0.4,4.7-0.4,0.5-0.3
                    c-1.7,0.1-4.6-0.6-6.2-0.1c2.4-0.7,3.5,0.7,1.3,0.2c-1.3-0.3-2.6-0.5-3.9-0.9c-1-0.3-2.2-0.9-3.2-1c-2.6-0.3,4.2,2.2,0.5,0.3
                    c-1.5-0.8-3.1-1.6-4.6-2.5c-0.4-0.2-0.7-0.5-1.1-0.7c-1.4-1-1.4-1-0.1,0c0.3,0.3,0.7,0.5,1,0.8c-0.7-0.5-1.3-1.1-2-1.7
                    c-1.6-1.4-3.1-3-4.5-4.6c-2.6-2.8,0.9,0.9,0.7,1c-0.1,0.1-1.6-2.4-1.8-2.7c-0.2-0.4-3.1-4.7-2.7-5.2c0.2-0.3,1.4,4.2,0.6,1.1
                    c-0.3-1.1-0.7-2.1-0.9-3.2c-0.3-1.1-0.5-2.2-0.7-3.3c-0.9-3.9,0,4.2,0.1-0.1c0-1.8,0-3.7,0-5.6c0.1-4.1-0.1,1.4-0.3,1.2
                    c-0.2-0.2,0.5-2.9,0.6-3.3c0.1-0.3,1.3-5.8,1.7-5.7c0.2,0-1.9,3.9-0.5,1.1c0.6-1.2,1.2-2.3,1.8-3.5c0.5-0.9,1.4-1.8,1.7-2.7
                    c0.9-2.4-3.2,3.5-0.4,0.4c1.2-1.3,2.3-2.6,3.6-3.8c0.8-0.7,1.7-1.4,2.4-2.1c1.8-1.9-4.1,2.5-0.4,0.4c1.9-1.1,3.7-2.2,5.7-3.2
                    c3.4-1.8-1.1,0.6-1.1,0.5c0-0.2,2.8-0.9,3.1-1c1.9-0.6,4-0.7,5.9-1.3c-4.2,1.2-2.8,0.3-1.2,0.3c1.2,0,2.3-0.1,3.5-0.1
                    c0.4,0,3.4,0,3.5,0.2c-0.1-0.3-4.9-1,0,0.1c0.5,0.1,6.4,1.4,6.4,1.9c0,0.2-3.9-1.9-1.1-0.5c1.2,0.6,2.3,1.2,3.5,1.8
                    c0.7,0.4,1.5,1,2.2,1.4c3.6,2-2.7-2.6,0.1,0c1.8,1.6,3.4,3.3,5.1,5c2.8,3-0.4-0.5-0.3-0.5c0.2-0.1,2,3.1,2.1,3.3
                    c0.4,0.8,0.9,1.5,1.3,2.3c0.3,0.6,0.6,1.2,0.9,1.8c0.6,1.4,0.4,0.8-0.8-1.7c0.5,0,1.7,5.9,1.9,6.4c0.1,0.4,0.2,0.9,0.3,1.3
                    c0.3,1.8,0.3,1.8,0,0c-0.2-1.8-0.3-1.7-0.1,0.1C537.9,284.6,537.9,285.5,537.9,286.4c0.2,10.5,9.1,20.5,20,20
                    c10.7-0.5,20.2-8.8,20-20c-0.4-28.1-18-52.4-44.3-62c-24.6-8.9-54.5-0.7-71,19.5c-17.6,21.5-21.1,52-6.5,76.1
                    c14.6,24.2,42.4,35.4,69.8,30.5c30.3-5.5,51.6-34.1,52.1-64.1c0.2-10.5-9.3-20.5-20-20C546.9,266.9,538,275.2,537.9,286.4z"></path></g></g></g></svg>

         <p style="font-family:'montserrat';font-weight:bolder;font-size:15px;margin-top:15px;">The bank seems busy while connecting your account, please try again later.</p>
         <button class="black-button-ok" @click="hideModal('bank-link-error')">OK</button>
        </div>
        </b-modal>
      </div>
      <!-----------------------  MODAL FOR BANK FIX FAILURE  !----------------->
</div>
</template>
<script>
import { db } from "../../firebaseConfig.js";
import api from "../../api/registration.js";
import onboarding_api from "../../api/onboarding.js";
import account_api from "../../api/account.js";
import { isMobile } from "mobile-device-detect";
import Success from "../Registration/Success.vue";
import SkipBankingSolutionModal from './SkipBankingSolutionModal.vue';
import Loading from "vue-loading-overlay";
import CanPayLoader from "../CustomLoader/CanPayLoader.vue"
export default {
	name: "Success",
  data() {
    return {
      props: ['params'],
	  isLoading: true,
      banking_solution:'mx',
      link_success: false,
      error_message: '',
      fullPage: true,
	  skip_current_banking_solution: false,
    };
  },
	components: {
    success: Success,
    Loading,
	SkipBankingSolutionModal,
	CanPayLoader
  },
  mounted() {
  },
	created() {
		if (isMobile) {
			this.mobile = true;
		}
		this.currentUser = localStorage.getItem("consumer_login_response")
		? JSON.parse(localStorage.getItem("consumer_login_response"))
		: null;
		localStorage.setItem("bank_link_success", true);
		setTimeout(function(){
			localStorage.removeItem("bank_link_success");
		}, 10000)
		this.$root.$emit("changeWhiteBackground", [false, true, "common"]);
		this.$root.$emit("show_header", true);
    if (localStorage.getItem('consumer_token') != null) {
		console.log(this.$route.params);
		if(this.$route.params.fix_connection == true){
			this.fixMxConnectCall(this.$route.params.active_bank_id)
		}
		else if(this.$route.params.hasOwnProperty('mx_user_type') && this.$route.params.mx_user_type == 'challenged'){
			this.action_type = "challenge";
		}else{
			this.storeBankDetails();
			this.action_type = 'bank_change';
		}
    } else if (localStorage.getItem('session_data') != null) { // Registration 
      this.sessionID = localStorage.getItem('session_data');
      this.action_type = 'registration';
      this.registration();
    } else if (localStorage.getItem('sessionId') != null) { // Onboarding
      this.sessionID = localStorage.getItem('sessionId');
      this.action_type = 'v1 Onboarding';
      this.onboadring();
    }
	},
	methods: {
	showModal(modal){
		this.$refs[modal].show();
	},
	hideModal(modal){
		if(modal == "bank-link-error" || modal == "bank-link-success"){
		 setTimeout(()=>{
		   this.$router.push("/pay");
		   setTimeout(()=>{
		    window.location.reload();
		   },300);
	     },400);
		}
		this.$refs[modal].hide();
	},
    setdata() {
      var self = this;
	  const data = {
          mx_action_needed: null
        }
      var washingtonRef = db
        .collection("users")
        .doc(String(this.currentUser.user_id));
      // Set the "capital" field of the city 'DC'
      return washingtonRef
        .update(data)
        .then(function () {
          console.log("Document successfully updated!");
        })
        .catch(function (error) {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
	fixMxConnectCall(curr_active_bank_id){
		let self = this;
		const payload = {
			active_bank_id:curr_active_bank_id
		}
		account_api.
		fixMxConnection(payload)
		.then((res)=>{
			this.showModal("bank-link-success");
		})
		.catch((err)=>{
			this.showModal("bank-link-error")
		})
	},
    showValidationModal(msg) {
			if (this.skip_current_banking_solution) {
				localStorage.setItem("skip_current_banking_solution", this.skip_current_banking_solution);
				this.skip_current_banking_solution = false;
				this.$bvModal.show("skip-banking-solution-modal");
			} else {
				this.error_message = msg;
				this.$refs["validation-modal"].show();
			}
		},
		hidevalidationModal() {
			let self = this;
			if (localStorage.getItem('consumer_token') != null) {
				this.$router.push("/banklinking");
			} else {
				this.$router.push("/login");
			}
		},
    //stores the bank details into canpay end
		storeBankDetails() {
			var self = this;
			self.isLoading = true;
      var selected_bank = JSON.parse(localStorage.getItem("selected_bank"));
			let request = {
				user_guid: self.$route.params.user_guid,
				member_guid: self.$route.params.member_guid,
				banking_solution:self.banking_solution,
				bank_name: selected_bank.bank_name,
				bank_id: selected_bank.id,
				batch_id: selected_bank.batch_id,
				is_finicity_delink: localStorage.getItem('delink_finicity_banking_solution') == 'true' ? true : false,
			};
			account_api
				.updateBank(request)
				.then((response) => {
					this.show = "update_success";
					const consumer_login_response = JSON.parse(localStorage.getItem("consumer_login_response"));
					consumer_login_response.account_no = response.data.account_no;
					localStorage.setItem("consumer_login_response", JSON.stringify(consumer_login_response));
					self.link_success = true;
					localStorage.removeItem('delink_finicity_banking_solution');
					self.getUserdetails();
				})
				.catch(function (err) {
					self.isLoading = false;
					self.skip_current_banking_solution = err?.response?.data?.data?.skip_current_banking_solution === 1 
														? true 
														: false;
					self.showValidationModal(err.response.data.message);
				});
		},
    registration: function () {
			let self = this;
			this.isLoading = true;
			var selected_bank = JSON.parse(localStorage.getItem("selected_bank"));
			var request = {
				sessionId: this.sessionID,
				banking_solution: self.banking_solution,
				bank_name: selected_bank.bank_name,
        bank_id: selected_bank.id,
        batch_id: selected_bank.batch_id,
        user_guid: self.$route.params.user_guid,
        member_guid: self.$route.params.member_guid,
			};
			api
				.registerUser(request)
				.then((response) => {
					if (response.code == 200) {
						this.isLoading = false;
						localStorage.setItem("user_id", response.data.user_id);
						localStorage.setItem("enrollment", true);
						self.link_success = true;
						if (
							typeof response.data.global_radar != "undefined" &&
							response.data.global_radar == 1
						) {
							self.showValidationModal("Registration Complete. You may now login.");
						} else {
							self.$router.push("/successbanklisting");
						}
					}
				})
				.catch((err) => {
					self.isLoading = false;
					self.skip_current_banking_solution = err?.response?.data?.data?.skip_current_banking_solution === 1 
														? true 
														: false;
					self.showValidationModal(err.response.data.message);
				});
		},
    getUserdetails(){
			var self = this;
			account_api
			.getUserdetails()
			.then(function (response) {
				if (response.code == 200) {
				localStorage.setItem(
					"consumer_login_response",
					JSON.stringify(response.data)
				);
				self.currentUser = localStorage.getItem("consumer_login_response")
				? JSON.parse(localStorage.getItem("consumer_login_response"))
				: null;
				self.$router.push("/successbanklisting");
				}
			})
			.catch(function (error) {
			});
		},
    onboadring() {
			let self = this;
			self.isLoading = true;
			var selected_bank = JSON.parse(localStorage.getItem("selected_bank"));
			var request = {
				session_id: self.sessionID,
				banking_solution: self.banking_solution,
				bank_name: selected_bank.bank_name,
        bank_id: selected_bank.id,
        batch_id: selected_bank.batch_id,
        user_guid: self.$route.params.user_guid,
        member_guid: self.$route.params.member_guid,
			};
			onboarding_api
				.Finalonboarding(request)
				.then((response) => {
				self.isLoading = true;
				if (response.code == 200) {
					self.link_success = true;
					localStorage.setItem("user_id", response.data.user_id);
					self.$router.push("/successbanklisting");
				}
				})
				.catch((err) => {
					self.isLoading = false;
					self.skip_current_banking_solution = err?.response?.data?.data?.skip_current_banking_solution === 1 
														? true 
														: false;
					self.showValidationModal(err.response.data.message);
				});
		},
    redirectBankListPage(){
      var self = this;
      self.$router.push('/banklisting');
    },
	callAccountOwnerApi(){
		console.log("Call the AccountOwner API");
		var self = this;
		self.isLoading = true;
		let request = {
			user_guid: self.$route.params.user_guid,
        	member_guid: self.$route.params.member_guid,
			user_id: self.currentUser.user_id,
			};
			account_api
				.callaccountownerapi(request)
				.then((response) => {
					if(response.code == 200){
						self.$router.push('/pay');
					}
					self.isLoading = false;
				})
				.catch(function (err) {
					self.isLoading = false;
				});
	}
	}
}
</script>
<style scoped>
.bg-findbank-success{
    background-color: #fff;
    border-radius: 10px;
}
.find-bank-success-button{
    background-color: #000;
    color:#fff;
    border-radius: 10px;
    border: none;
    padding: 15px 80px;
}
#bank-link-success___BV_modal_content_{
  background-color:#ffffff;
}
#bank-link-success___BV_modal_body_{
  background-color:#ffffff;
  border-radius:10px;
}
#bank-link-error___BV_modal_content_{
  background-color:#ffffff;
}
#bank-link-error___BV_modal_body_{
  background-color:#ffffff;
  border-radius:10px;
}
</style>
